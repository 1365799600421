import { FaPhone, FaMapMarker, FaEnvelope } from 'react-icons/fa';
import grass from "../assets/grass.jpg";
import { useState } from 'react'; 

const Contact = () => {

    const [formData, setFormData] = useState({
        name:'',
        email:'',
        message:'',
    });
    
    const handleChange = (e) =>{
        const {name,value} = e.target;
        setFormData({
            ...formData,
            [name]:value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here (you can send the data to a server, etc.)
        const formData = new FormData(e.target);
        formData.append("access_key","09f4b0ed-8b4b-48a8-b4f5-dbeef740db63");
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
        const res = await fetch("https://api.web3forms.com/submit",{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                Accept:"application/json"
            },
            body:json
        }).then((res)=>res.json());

        if(res.success){
            console.log("Success",res);
        }
        // Reset the form after submission
        setFormData({
            name: '',
            email: '',
            message: '',
            });
        };

    return (
      <div id = "contact" className='min-h-[600px]'>
        <div className='grid grid-cols-1 sm:grid-cols-2'>
          {/* Image and Info */}
          <div
            className="bg-cover bg-center h-full sm:h-[600px] flex items-center justify-center"
            style={{ backgroundImage: `url(${grass})` }}
          >
            {/* Your content goes here */}
            <div className="text-white text-center bg-slate-800 opacity-90 rounded-md h-[90%] w-[80%] sm:h-[50%] sm:w-[70%] md:p-12 mb-4">
              <h1 className="text-xl lg:text-5xl font-bold">Contact Us</h1>
              <ul className='mt-8 '>
                <li className='m-4 text-left flex items-center  text-xs lg:text-lg'>
                  <FaMapMarker size={30} className="mr-2" />
                  215 N Stanton Rd, El Paso, TX, US
                </li>
                <li className='m-4 text-left flex items-center  text-xs lg:text-lg'>
                  <FaPhone size={30} className="mr-2" />
                  (915) 505-0069 (text/call)
                </li>
                <li className='m-4 text-left flex items-center  text-xs lg:text-lg'>
                  <FaEnvelope size={30} className="mr-2" />
                  HomeScape915@gmail.com
                </li>
              </ul>
            </div>
          </div>
          {/* Contact Form */}
          <div className="p-8">
            {/* Your contact form goes here */}
            <h2 className='text-xl sm:text-4xl font-bold text-center m-4'>Message Us</h2>
            <p className='text-lg font-semibold text-center'>Please let us know how we can help, and will provide you a quote as soon as possible</p>
            <form onSubmit={handleSubmit} className="max-w-md">
                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-semibold mb-1">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-semibold mb-1">
                        Email:
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="message" className="block text-sm font-semibold mb-1">
                        Message:
                    </label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                        required
                    ></textarea>
                </div>
                    <button
                    type="submit"
                    className="bg-[#22C55E] text-white px-4 py-2 rounded-md hover:bg-green-300"
                    >
                    Submit
                    </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
  export default Contact;