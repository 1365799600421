import { useEffect } from "react";
import Home from "./views/Home";
import ReactGA from 'react-ga4';
import companyLogo from "./assets/companyLogo.png";




ReactGA.initialize("G-EBVN8W7Y3D");


function App() {
  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });
  },[]);
  return (
    <div >
      <head>
        
      </head>
      <Home/>
    </div>
  );
}

export default App;
