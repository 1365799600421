
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'
import "leaflet-defaulticon-compatibility"; 
export const Map = () => {
  const position = [31.759911, -106.487003];
  const zoomLevel = 12.8;
 
  return (
    <MapContainer zoom={zoomLevel} center={position} scrollWheelZoom={false} style={{width:"100%", height:"50vh", zIndex: 1}}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <Marker position={position}>
        <Popup>
          <b>Home Scape</b> <br/>
          <b>Address</b>: <a href="https://www.google.com/maps/place/215+n+stanton+st+el+paso/@31.7598325,-106.4867134,3a,75y,231.15h,90t/data=!3m4!1e1!3m2!1szeo324vSYsDyXenj7aIodA!2e0!4m2!3m1!1s0x86e759026d8ab169:0x56c2114dfe561f34?sa=X&ved=2ahUKEwiCobWNlMSCAxV4l2oFHf5wC_cQxB16BAgREAA">215 N Stanton Rd, El Paso, TX, 79901</a>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

const Location = () => {
    return ( 
        <div className="w-full h-full items-center justify-center">
            {/* Map */}
            <div className="flex flex-row items-center justify-center ">
                <Map/>
            </div>
        </div>
     );
}
 
export default Location;